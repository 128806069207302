<template>
  <div>
    <b-card no-body class="mb-5">
      <b-overlay :show="loading" rounded="sm" no-fade>
        <div class="m-2">
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="3"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Show</label>
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>entries</label>
            </b-col>

            <b-col
              cols="12"
              md="4"
              class="d-flex align-items-left justify-content-end mb-1 mb-md-0 "
            >
              <!-- <v-select :options="statusOptions" v-model="status" class="per-page-selector d-inline-block w-100" label="label" :reduce="(val) => val.value" placeholder="Status" /> -->
              <!-- :disable-branch-nodes="true" -->
              <treeselect
                value-consists-of="LEAF_PRIORITY"
                :multiple="true"
                :options="statusOptions"
                v-model="treeStatus"
                valueFormat="object"
                :limit="2"
                class="per-page-selector d-inline-block w-100"
                style="position: absolute"
                placeholder="Status"
              />
            </b-col>

            <b-col
              cols="12"
              md="2"
              class="d-flex align-items-left justify-content-start "
            >
              <flat-pickr
                placeholder="Date Range"
                v-model="rangeDate"
                onClose="testClose"
                class="form-control"
                :config="{ mode: 'range' }"
              />
            </b-col>

            <!-- Search -->
            <b-col cols="12" md="3">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="filter"
                  class="d-inline-block mr-1"
                  cle
                  placeholder="Search..."
                />
                <b-button
                  variant="primary"
                  router-link
                  to="/purchase/quote-add"
                  v-if="$Can('purchase_quote_create')"
                >
                  <span class="text-nowrap">Add Quote</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          striped
          hover
          ref="refUserListTable"
          class="position-relative mb-5"
          :style="totalRows < 3 ? 'height: 500px' : ''"
          :items="listPurchases"
          @sort-changed="sortingChanged"
          :no-local-sorting="true"
          selectable
          select-mode="single"
          responsive
          :small="true"
          head-variant="dark"
          :fields="purchaseHeaders"
          show-empty
          empty-text="No matching records found"
          @row-selected="onRowSelected"
          :per-page="perPage"
        >
          <!-- Column: lienCustomerId -->
          <template #cell(title)="data">
            <span>{{ data.item.title ? data.item.title : "N/A" }}</span>
          </template>

          <template #cell(lienCustomerId)="data">
            <span>{{
              data.item.lienCustomerId ? data.item.lienCustomerId : "N/A"
            }}</span>
          </template>
          <template #cell(sellerCustomerId)="data">
            <span>{{
              data.item.sellerCustomerId ? data.item.sellerCustomerId : "N/A"
            }}</span>
          </template>
          <template #cell(sellerEmail)="data">
            <span>{{
              data.item.sellerEmail ? data.item.sellerEmail : "N/A"
            }}</span>
          </template>
          <template #cell(sellerName)="data">
            <span>{{
              data.item.sellerName ? data.item.sellerName : "N/A"
            }}</span>
          </template>
          <template #cell(sellerPhoneNumber)="data">
            <span>{{
              data.item.sellerPhoneNumber ? data.item.sellerPhoneNumber : "N/A"
            }}</span>
          </template>
          <template #cell(sellerType)="data">
            <b-badge
              v-if="data.item.sellerType == '1'"
              pill
              variant="light-primary"
              class="badge-glow"
              >Individual</b-badge
            >
            <b-badge
              v-if="data.item.sellerType == '2'"
              pill
              variant="light-warning"
              class="badge-glow"
              >Business</b-badge
            >
          </template>

          <template #cell(sellerContactPerson)="data">
            <span>{{
              data.item.sellerContactPerson
                ? data.item.sellerContactPerson
                : "N/A"
            }}</span>
          </template>
          <template #cell(sellerContactInfo)="data">
            <span>{{
              data.item.sellerContactInfo ? data.item.sellerContactInfo : "N/A"
            }}</span>
          </template>

          <template #cell(sellerTradeBalance)="data">
            <span>{{
              data.item.sellerTradeBalance
                ? formatPrice(data.item.sellerTradeBalance, 2)
                : formatPrice(0)
            }}</span>
          </template>

          <template #cell(coOwnerCustomersId)="data">
            <span>{{
              data.item.coOwnerCustomersId
                ? data.item.coOwnerCustomersId
                : "N/A"
            }}</span>
          </template>
          <template #cell(coSellerName)="data">
            <span>{{
              data.item.coSellerName ? data.item.coSellerName : "N/A"
            }}</span>
          </template>

          <template #cell(lienHolder)="data">
            <span>{{
              data.item.lienHolder ? data.item.lienHolder : "N/A"
            }}</span>
          </template>
          <template #cell(approvedBy)="data">
            <span>{{
              data.item.approvedBy ? data.item.approvedBy : "N/A"
            }}</span>
          </template>
          <template #cell(totalMsrpOptions)="data">
            <span>{{
              data.item.totalMsrpOptions ? data.item.totalMsrpOptions : "N/A"
            }}</span>
          </template>
          <template #cell(accessories)="data">
            <span>{{
              data.item.accessories ? data.item.accessories : "N/A"
            }}</span>
          </template>

          <template #cell(priceContractType)="data">
            <b-badge
              v-if="data.item.priceContractType == 1"
              pill
              variant="light-success"
              class="badge-glow"
              >Buy-in</b-badge
            >
            <b-badge
              v-if="data.item.priceContractType == 2"
              pill
              variant="light-info"
              class="badge-glow"
              >Trade-in
              <feather-icon
                v-if="data.item.tradeStatus == 1 || data.item.tradeStatus == 2"
                icon="CheckCircleIcon"
                size="12"
            /></b-badge>
            <b-badge
              v-if="data.item.priceContractType == 3"
              pill
              variant="light-primary"
              class="badge-glow"
              >Lease Return</b-badge
            >
          </template>
          <template #cell(creditIssued)="data">
            <span>{{
              data.item.creditIssued
                ? formatPrice(data.item.creditIssued, 2)
                : formatPrice(0)
            }}</span>
          </template>
          <template #cell(gstRateVehicle)="data">
            <span>{{
              data.item.gstRateVehicle ? data.item.gstRateVehicle : "N/A"
            }}</span>
          </template>
          <template #cell(pstRateVehicle)="data">
            <span>{{
              data.item.pstRateVehicle ? data.item.pstRateVehicle : "N/A"
            }}</span>
          </template>
          <template #cell(payableBySeller)="data">
            <span>{{
              data.item.payableBySeller
                ? formatPrice(data.item.payableBySeller, 2)
                : formatPrice(0)
            }}</span>
          </template>
          <template #cell(id)="data">
            <strong class="text-primary">#{{ Number(data.item.id) }}</strong>
          </template>
          <template #cell(quoteDate)="data">
            <span>{{
              data.item.quoteDate ? dateFormat(data.item.quoteDate) : "N/A"
            }}</span>
          </template>
          <template #cell(completionDate)="data">
            <span>{{
              data.item.completionDate ? data.item.completionDate : "N/A"
            }}</span>
          </template>
          <template #cell(location)="data">
            <span>{{ data.item.location ? data.item.location : "N/A" }}</span>
          </template>
          <template #cell(userId)="data">
            <span>{{ data.item.userId ? data.item.userId : "N/A" }}</span>
          </template>
          <template #cell(condition)="data">
            <b-badge
              v-if="data.item.condition == 'NEW'"
              active
              variant="light-info"
              class="badge-glow "
              >New</b-badge
            >
            <b-badge
              v-if="data.item.condition == 'USED'"
              variant="light-success"
              class="badge-glow "
              >Used
            </b-badge>
          </template>
          <template #cell(status)="data">
            <b-badge
              v-if="data.item.status == 'A'"
              pill
              variant="dark"
              class="badge-glow"
              >Draft</b-badge
            >
            <b-badge
              v-if="data.item.status == 'B'"
              pill
              variant="primary"
              class="badge-glow"
              >Pending manager</b-badge
            >
            <b-badge
              v-if="data.item.status == 'C'"
              pill
              variant="warning"
              class="badge-glow"
              >Approved</b-badge
            >
            <b-badge
              v-if="data.item.status == 'D'"
              pill
              variant="danger"
              class="badge-glow"
              >Not Approved</b-badge
            >
            <b-badge
              v-if="
                data.item.status == 'E' && data.item.statusCustomer == 'blank'
              "
              pill
              variant="info"
              class="badge-glow"
              >Sent To Client</b-badge
            >
            <b-badge
              v-if="
                data.item.status == 'E' && data.item.statusCustomer == 'approve'
              "
              pill
              variant="light-success"
              class="badge-glow"
              >Sent To Client <br /><small> Client Approved</small></b-badge
            >
            <b-badge
              v-if="
                data.item.status == 'E' &&
                  data.item.statusCustomer == 'notapprove'
              "
              pill
              variant="light-danger"
              class="badge-glow"
              >Sent To Client <br /><small>Client Not Approved</small></b-badge
            >
            <b-badge
              v-if="data.item.status == 'F'"
              pill
              variant="success"
              class="badge-glow  "
              >Win
            </b-badge>
            <b-badge
              v-if="data.item.status == 'P'"
              pill
              variant="secondary"
              class="badge"
              >Lost</b-badge
            >
            <b-badge
              v-if="data.item.status == 'J'"
              pill
              variant="danger"
              class="badge"
              >Canceled Purchase</b-badge
            >
            <b-badge
              v-if="data.item.status == 'H' && data.item.excelImport == false"
              pill
              variant="success"
              class="badge"
              >Win-Closed</b-badge
            >
            <b-badge
              v-if="data.item.status == 'H' && data.item.excelImport == true"
              pill
              variant="success"
              class="badge"
              >Win-Closed - Excel</b-badge
            >
          </template>
          <template #cell(payment)="data">
            <b-badge
              variant="light-success"
              v-if="data.item.payment == 'yes'"
              style="font-size: 70%"
              >Payment Made</b-badge
            >
            <b-badge
              variant="light-danger"
              v-if="data.item.payment == 'no' && data.item.status == 'F'"
              style="font-size: 70%"
              >Payment Pending</b-badge
            >
            <b-badge
              variant="light-info"
              v-if="data.item.payment == 'no' && data.item.status != 'F'"
              style="font-size: 70%"
              >No Payment</b-badge
            >
          </template>

          <template #cell(extColourValue)="data">
            <span>{{
              data.item.extColourValue ? data.item.extColourValue : "N/A"
            }}</span>
          </template>

          <template #cell(intColourValue)="data">
            <span>{{
              data.item.intColourValue ? data.item.intColourValue : "N/A"
            }}</span>
          </template>
          <template #cell(makeValue)="data">
            <span>{{ data.item.makeValue ? data.item.makeValue : "N/A" }}</span>
          </template>
          <template #cell(modelValue)="data">
            <span>{{
              data.item.modelValue ? data.item.modelValue : "N/A"
            }}</span>
          </template>
          <template #cell(modelYearValue)="data">
            <span>{{
              data.item.modelYearValue ? data.item.modelYearValue : "N/A"
            }}</span>
          </template>
          <template #cell(baseMsrp)="data">
            <span>{{
              data.item.baseMsrp
                ? formatPrice(data.item.baseMsrp, 2)
                : formatPrice(0)
            }}</span>
          </template>
          <template #cell(msrpCurrency)="data">
            <span>{{
              data.item.msrpCurrency ? data.item.msrpCurrency : "N/A"
            }}</span>
          </template>
          <template #cell(origenalMarket)="data">
            <span>{{
              data.item.origenalMarket ? data.item.origenalMarket : "N/A"
            }}</span>
          </template>

          <template #cell(inventoryLocation)="data">
            <span>{{
              data.item.inventoryLocation ? data.item.inventoryLocation : "N/A"
            }}</span>
          </template>
          <template #cell(received)="data">
            <b-badge
              variant="success"
              v-if="data.item.received == 'yes'"
              style="font-size: 70%"
              >Vehicle Received</b-badge
            >
            <b-badge
              variant="danger"
              v-if="data.item.received == 'no'"
              style="font-size: 70%"
              >Vehicle Not Received</b-badge
            >
          </template>

          <template #cell(consigment)="data">
            <b-badge
              variant="success"
              v-if="data.item.consigment == true"
              style="font-size: 70%"
              >Consignment</b-badge
            >
            <b-badge
              variant="danger"
              v-if="data.item.consigment == false"
              style="font-size: 70%"
              >Non Consignment</b-badge
            >
          </template>
          <template #cell(stockNumber)="data">
            <span>{{
              data.item.stockNumber ? data.item.stockNumber : "N/A"
            }}</span>
          </template>
          <template #cell(vinNo)="data">
            <span>{{ data.item.vinNo ? data.item.vinNo : "N/A" }}</span>
          </template>

          <template #cell(user)="data">
            <span>{{ data.item.user ? data.item.user : "N/A" }}</span>
          </template>

          <template #cell(additionalOptions)="data">
            <span>{{
              data.item.additionalOptions ? data.item.additionalOptions : "N/A"
            }}</span>
          </template>
          <template #cell(additionalOptionsTotal)="data">
            <span>{{
              data.item.additionalOptionsTotal
                ? formatPrice(data.item.additionalOptionsTotal, 2)
                : formatPrice(0)
            }}</span>
          </template>
          <template #cell(appraisalNotes)="data">
            <span>{{
              data.item.appraisalNotes ? data.item.appraisalNotes : "N/A"
            }}</span>
          </template>
          <template #cell(appraisedVehiclePrice)="data">
            <span>{{
              data.item.appraisedVehiclePrice
                ? formatPrice(data.item.appraisedVehiclePrice, 2)
                : formatPrice(0)
            }}</span>
          </template>
          <template #cell(creditDue)="data">
            <span>{{
              data.item.creditDue
                ? formatPrice(data.item.creditDue, 2)
                : formatPrice(0)
            }}</span>
          </template>
          <template #cell(msrpOptionsValue)="data">
            <span>{{
              data.item.msrpOptionsValue ? data.item.msrpOptionsValue : "N/A"
            }}</span>
          </template>
          <template #cell(msrpTotal)="data">
            <span>{{
              data.item.msrpTotal
                ? formatPrice(data.item.msrpTotal, 2)
                : formatPrice(0)
            }}</span>
          </template>
          <template #cell(notes)="data">
            <span>{{ data.item.notes ? data.item.notes : "N/A" }}</span>
          </template>
          <template #cell(payoutType)="data">
            <span>{{
              data.item.payoutType ? data.item.payoutType : "N/A"
            }}</span>
          </template>

          <template #cell(listingPrice)="data">
            <span>{{
              data.item.listingPrice
                ? formatPrice(data.item.listingPrice, 2)
                : formatPrice(0)
            }}</span>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data" class="bg-danger">
            <b-dropdown variant="link" no-caret>
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item
                @click="
                  $router.push({
                    name: 'quote-preview',
                    params: { id: data.item.id },
                  })
                "
                v-if="data.item.archive == 'no'"
                v-show="$Can('purchase_quote_show')"
              >
                <feather-icon icon="EyeIcon" />
                <span class="align-middle ml-50">Quote Preview</span>
              </b-dropdown-item>

              <b-dropdown-item
                @click="
                  $router.push({
                    name: 'inventory-view',
                    params: { id: data.item.inventoryId },
                  })
                "
                v-if="data.item.archive == 'no'"
                v-show="$Can('inventory_show')"
              >
                <feather-icon icon="ShoppingCartIcon" />
                <span class="align-middle ml-50">Vehicle Preview </span>
              </b-dropdown-item>

              <b-dropdown-item
                v-show="$Can('purchase_quote_edit')"
                v-if="
                  data.item.priceContractType != 2 &&
                    (data.item.status == 'A' ||
                      data.item.status == 'B' ||
                      data.item.status == 'C' ||
                      data.item.status == 'D')
                "
                @click="
                  $router.push({
                    name: 'quote-edit',
                    params: { id: data.item.id },
                  })
                "
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>

              <b-dropdown-item
                @click="pdfDownload(data.item.id)"
                v-show="$Can('purchase_quote_show')"
              >
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Download PDF</span>
              </b-dropdown-item>

              <b-dropdown-item
                @click="sendToArchive(data.item, data)"
                v-if="data.item.archive == 'no'"
                v-show="
                  (data.item.status == 'P' ||
                    data.item.status == 'H' ||
                    data.item.status == 'J') &&
                    $Can('purchase_send_archive')
                "
              >
                <feather-icon icon="SendIcon" />
                <span class="align-middle ml-50">Send To Archive </span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                {{ dataMeta.of }} entries</span
              >
            </b-col>

            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>

        <a
          class="ml-2 mb-2 mr-2 float-right text-primary"
          v-show="$Can('view_purchase_archive_records')"
          v-if="where == 'quote-list'"
          @click="$router.push({ name: 'archive-quote-list' })"
          >Show archived records</a
        >
        <a
          class="ml-2 mb-2 mr-2 float-right text-primary"
          v-show="$Can('view_purchase_archive_records')"
          v-else
          @click="$router.push({ name: 'quote-list' })"
          >Show active records</a
        >
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import {
  BImg,
  BOverlay,
  BCard,
  BFormGroup,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import store from "@/store";
import purchaseStoreModule from "../purchaseStoreModule";
import { onUnmounted } from "@vue/composition-api";
import vSelect from "vue-select";
import router from "@/router";
import { provideToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
import _ from "lodash";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  components: {
    Treeselect,
    vSelect,
    BImg,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BFormGroup,
    provideToast,
    ToastificationContent,
    flatPickr,
    moment,
  },

  setup() {
    const PURCHASE_APP_STORE_MODULE_NAME = "purchase";

    // Register module
    if (!store.hasModule(PURCHASE_APP_STORE_MODULE_NAME))
      store.registerModule(PURCHASE_APP_STORE_MODULE_NAME, purchaseStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PURCHASE_APP_STORE_MODULE_NAME))
        store.unregisterModule(PURCHASE_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    // { key: 'quoteDate', show: true, label: 'Quote Date', class: 'text-center', sortable: false, class: 'text-center', thStyle: 'width: 10%' },

    const tempStatusOptions = [
      { label: "Draft", value: "A" },
      { label: "Pending Manager", value: "B" },
      { label: "Approved", value: "C" },
      { label: "Not Approved", value: "D" },
      { label: "Sent To Client", value: "E" },
      { label: "Win", value: "F" },
      { label: "Loss", value: "P" },
      { label: "Canceled Purchase", value: "J" },
      { label: "Win-Closed", value: "H" },
    ];
    return {
      rangeDate: null,
      filterDateStart: null,
      filterDateEnd: null,
      where: router.currentRoute.name,
      filter: null,

      perPage: localStorage.getItem("purchaseShow") ?? 10,
      fetchPurchase: [],
      listPurchases: [],
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      colWidthFactor: null,
      totalRows: 0,
      baseURL: store.state.app.baseURL,
      loading: false,
      statusOptions: [],
      tempStatusOptions,
      purchaseHeaders: [],
      status: null,
      treeStatus: null,
      sortBy: "id",
      sortDesc: true,
    };
  },
  computed: {
    dataMeta() {
      return {
        from:
          this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to:
          this.perPage * (this.currentPage - 1) + Number(this.perPage) <
          this.totalRows
            ? this.perPage * (this.currentPage - 1) + Number(this.perPage)
            : this.totalRows,
        of: this.totalRows,
      };
    },
  },

  watch: {
    perPage: {
      handler: function(val, before) {
        localStorage.setItem("purchaseShow", val);
        this.listRefresh();
      },
    },

    currentPage: {
      handler: function(val, before) {
        this.listRefresh();
      },
    },

    filter: _.debounce(function(newVal) {
      this.listRefresh();
    }, 500),

    sortDesc: {
      handler: function(val, before) {
        if (this.sortBy != "") {
          this.listRefresh();
        }
      },
    },

    // status: {
    //   handler: function(val, before) {
    //     this.listRefresh();
    //   },
    // },

    treeStatus: {
      handler: function(val, before) {
        if (before == null) {
          // this.listRefresh();
        }
        if (before != null) {
          if (val.length != before.length) {
            this.listRefresh();
          }
        }
      },
    },

    rangeDate: {
      handler: function(val, before) {
        if (val.includes("to")) {
          this.filterDateStart = this.rangeDate.split(" to ")[0];
          this.filterDateEnd = this.rangeDate.split(" to ")[1];

          this.listRefresh();
        }
      },
    },
  },

  methods: {
    listRefresh() {
      if (this.where == "quote-list") {
        this.getItems("getAllQuote");
      } else {
        this.getItems("getAllArchiveQuote");
      }
    },

    dateFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("DD-MM-YYYY");
      }
    },

    sortingChanged(val) {
      this.sortBy = val.sortBy;
      this.sortDesc = val.sortDesc;
    },

    pdfDownload(val) {
      var link = "pdfDownload";
      if (this.where == "quote-list") {
        link = "pdfDownload";
      } else {
        link = "pdfArchiveDownload";
      }

      store.dispatch("purchase/" + link, val).then((response) => {
        if (response.status == 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;

          if (this.where == "quote-list") {
            link.setAttribute("download", "Quote_" + Number(val) + ".pdf");
          } else {
            link.setAttribute(
              "download",
              "Archive_Quote_" + Number(val) + ".pdf"
            );
          }

          document.body.appendChild(link);
          link.click();

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Successful",
              text: "✔️ PDF Downloaded Successfully",
              icon: "ThumbsUpIcon",
              variant: "success",
            },
          });

          setTimeout(function() {
            this.show = false;

            //  router.push({ name: "inventory-edit", params: { id: response.data.inventory.id } });
            //  router.push({ name: 'inventory-list' });
          }, 1000);
        }
      });
    },

    sendToArchive(val, data) {
      console.log(val);
      if (val.creditDueLeft > 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "The Quote can not be archived",
            text: "⚠️ The Quote has unused balance",
            icon: "ThumbsDownIcon",
            variant: "warning",
          },
        });
      } else {
        this.loading = true;

        var value = {
          id: val.id,
          updated_at: val.updated_at,
        };

        store.dispatch("purchase/sendToArchive", value).then((response) => {
          if (response.data != "alreadySent") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Successful",
                text: "✔️ The Quote sent to archive",
                icon: "ThumbsUpIcon",
                variant: "success",
              },
            });
            this.listPurchases.splice(
              (this.currentPage - 1) * this.perPage + data.index,
              1
            );
            this.totalRows--;
            this.currentPage = 1;
            this.loading = false;
          } else {
            this.loading = false;

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Please refresh the page.",
                text: "⚠️ This quote has already been archived.",
                icon: "RefreshCwIcon",
                variant: "warning",
              },
            });
          }
        });
      }
    },

    onRowSelected(item) {
      if (item.length != 0) {
        if (this.$Can("purchase_quote_show")) {
          if (this.where == "quote-list") {
            router.push({ name: "quote-preview", params: { id: item[0].id } });
          } else {
            router.push({
              name: "archive-quote-preview",
              params: { id: item[0].id },
            });
          }
        }
      }
    },

    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(",", ".");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },

    filterTreeSelect() {
      this.statusOptions = [];
      let treeOptionsStatus = [
        {
          id: 1000000,
          label: "Status",
          children: [],
        },
      ];

      this.tempStatusOptions.forEach((element) => {
        let setStatus = {
          id: element.value,
          label: element.label,
          value: element.value,
          is: "status",
        };
        treeOptionsStatus[0].children.push(setStatus);
      });

      this.statusOptions = treeOptionsStatus;
    },

    getItems(val) {
      this.loading = true;
      store
        .dispatch("purchase/" + val, {
          perPage: this.perPage,
          page: this.currentPage,
          sortBy: this.sortBy,
          sortDesc: this.sortDesc,
          filter: this.filter,
          status: this.treeStatus,
          filterDateStart: this.filterDateStart,
          filterDateEnd: this.filterDateEnd,
        })
        .then((res) => {
          let filterObj = {
            perPage: this.perPage,
            status: this.treeStatus ? this.treeStatus : [],
          };

          //   if (filterObj.status.length > 0) {
          localStorage.setItem("filterPurchaseList", JSON.stringify(filterObj));
          //   }

          this.totalRows = res.data.data.total;
          this.fetchPurchase = res.data.data.data;
          this.listPurchases = res.data.data.data;
          var arrayStatus = [];
          //this.statusOptions = [];

          res.data.data.data.forEach((element) => {
            arrayStatus.push(element.status);
          });

          // const uniqeStatus = Array.from(new Set(arrayStatus));
          // this.tempStatusOptions.forEach((element) => {
          //   uniqeStatus.forEach((item) => {
          //     if (element.value == item) this.statusOptions.push(element);
          //   });
          // });

          this.createTable(res.data.headers, res.data.columns);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    createTable(userheaders, headerColumns) {
      if (userheaders) {
        userheaders.forEach((items) => {
          this.colWidthFactor =
            this.colWidthFactor +
            headerColumns.find((x) => x.value == items).percentage;
        });

        userheaders.forEach((items) => {
          var columns = headerColumns.find((x) => x.value == items);
          var width =
            "width: " +
            String(columns.percentage * (95 / this.colWidthFactor)) +
            "%";

          if (
            items == "id" ||
            items == "quoteDate" ||
            items == "status" ||
            items == "priceContractType" ||
            items == "payment" ||
            items == "completionDate"
          ) {
            this.purchaseHeaders.push({
              key: items,
              label: columns.title,
              sortable: true,
              class: "text-center",
              thStyle: width,
            });
          } else {
            this.purchaseHeaders.push({
              key: items,
              label: columns.title,
              sortable: false,
              class: "text-center",
              thStyle: width,
            });
          }
        });
        if (
          this.$Can("purchase_quote_show") ||
          this.$Can("purchase_quote_edit")
        ) {
          this.purchaseHeaders.push({
            key: "actions",
            class: "text-center",
            thStyle: "width: 5%",
          });
        }
        this.loading = false;
      }
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    storagePurchaseItem() {
      let key = JSON.parse(localStorage.getItem("filterPurchaseList"));
      if (key == null) {
        this.listRefresh();
      }
      if (key) {
        this.perPage = key.perPage ? key.perPage : 10;
        this.page = key.page ? key.page : 1;

        let array = [];
        if (key.status.length > 0) {
          key.status.forEach((element) => {
            array.push(element);
          });
        }
        this.treeStatus = array;
        this.listRefresh();
      }
    },
  },

  mounted() {
    // this.listRefresh();
    this.filterTreeSelect();
    this.storagePurchaseItem();
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
